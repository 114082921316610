/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";

/* === Core Styles === */
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/loaders/loaders";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";

@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Dosis", sans-serif;
  // transition: all 0.4s ease-in-out;
}

body {
  background: white !important;
}

:root {
  /* Colors */

  --blue: #007bff;
  --white-main: #f0e1cf;
  --white-text: #c2beba;
  --lite-red: #ee5f67;
  --lite-yellow: #f6d68d;
  --lite-grey: #979694;
  --lite-grey-text: #706a63;
  --black-bg: #1c1b1b;
  --lite-black-1: #262626;
  --lite-black-2: #282828;
  --lite-black-3: #343434;
  --lite-black-4: #272727;
  --lite-black-5: #212121;
  --lite-black-6: #2c2c2c;
  --lite-black-7: #222222;
  --lite-black-8: #2f2f2f;
  --lite-black-9: #5b5956;

  --lite-border: #c6bcbc;
  --lite-border-2: #b7b8b3;
  --lite-border-3: #d9d8d1;
  --lite-border-4: #c9c9c8;
  --black-lite-border: #383838;

  --black-1: #232323;
  --black-2: #1a1d1e;

  --cancel: #ee5f67;
  --pending: #cf8d29;
  --success: #4caf50;

  --lite-theme-bg-1: #f2f3ed;
  --lite-theme-bg-2: #e2e3db;
  --lite-theme-bg-3: #fbfbfa;
  --lite-theme-bg-4: #ffffff;
  --lite-theme-bg-5: #eaebe4;
  --lite-theme-bg-6: #e4e5dd;
  --lite-theme-bg-7: #e4e4da;
  --lite-theme-bg-8: #f9f9f3;
  --lite-theme-bg-9: #fefff8;
  --lite-theme-bg-10: #f4f5e9;

  --yellow-solid: #979696;

  --lite-theme-logoBg: #f9faf5;
  --lite-theme-headerBottom: #dadbd5;

  --lite-theme-main-text: #575555;
  --blue-bg: #65fce1;

  --box-shadow: 0px 0px 17px -4px grey;

  /* New Update Colors  */
  --black: #000;
  --new-blue: #007bff;
  --new-lite-black: #161c2d;
  --new-lite-grey: #4a545e;
  --new-white: #fff;
  --new-bg: #f9f9f9;
  --box-bg: #f6f6f6;

  /* Font Family */
  --dosis: Dosis;
}

.sidebarnone {
  display: none !important;
}

body {
  background: white !important;
}
.resetpasswordss .fa-eye,
.resetpasswordss .fa-eye-slash {
  padding: 8px;
}

.view,
.viewp {
  color: #000 !important;
}
.viewp {
  font-size: 19px;
}
.viewimg {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.svgdownload {
  color: #000;
  font-size: 24px;
  cursor: pointer;
}
.svgdownload:hover {
  color: var(--new-blue);
}
.userlist {
  overflow-x: auto;
}
.userlist .table th,
.userlist .table td {
  border-top: 1px solid transparent !important;
}
.userlist .table thead tr th {
  font-size: 19px;
}
.userlist .table tbody tr td {
  font-size: 18px;
  font-weight: 500;
}
.userlist .table th,
.table td {
  padding: 1.3rem 0.9375rem;
}
.facopy {
  margin-left: 12px;
  cursor: pointer;
}
.facopy:hover {
  color: var(--new-blue);
}

.menu-icon svg {
  color: #007bff !important;
  font-size: 17px;
}
.commonBtn {
  align-items: center;
  background: #007bff !important;
  background: var(--new-blue) !important;
  border-radius: 10px;
  border-radius: 6px;
  color: #fff !important;
  color: var(--new-white) !important;
  cursor: pointer;
  display: flex;
  // font-size: 11px;
  font-weight: 700;
  justify-content: center;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-transform: uppercase;
  border: 1px solid var(--new-blue) !important;
}

.commonBtn:hover {
  background-color: var(--new-white) !important;
  color: var(--black) !important;
  border: 1px solid var(--new-blue) !important;
}

.commonBtn_snd {
  align-items: center;
  background: transparent !important;
  border-radius: 10px;
  border-radius: 6px;
  color: black !important;
  cursor: pointer;
  display: flex;
  // font-size: 11px;
  font-weight: 700;
  justify-content: center;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-transform: uppercase;
  border: 1px solid var(--new-blue) !important;
}

.commonBtn_snd:hover {
  background-color: var(--new-blue) !important;
  color: white !important;
  border: 1px solid var(--new-blue) !important;
}

.content-wrapper {
  background-color: white !important;
  min-height: calc(100vh - 174px) !important;
}

.content-wrapper .card {
  background-color: white !important;
  border-radius: 13px;
}

.login_sec h4 {
  color: var(--lite-grey) !important;
  font-weight: 600 !important;
}

.login_sec h6 {
  color: var(--lite-grey) !important;
  font-weight: 600 !important;
}

.auth form .form-group .form-control {
  border: 1px solid var(--lite-border-3) !important;
  color: var(--lite-grey) !important;
  border-radius: 0.375rem;
}

.login_sec .text-muted {
  color: var(--lite-grey) !important;
}

.login_sec .forgot_pw {
  text-decoration: underline;
}

.login_sec .forgot_pw:hover {
  color: var(--blue) !important;
}

.login_sec .dont_acc {
  color: var(--lite-grey) !important;
  font-weight: 600;
}

.dont_acc .text-primary {
  color: var(--lite-grey) !important;
  text-decoration: underline;
}

.dont_acc .text-primary:hover {
  color: var(--blue) !important;
}

.auth .brand-logo img {
  width: 70px !important;
}

.login_sec .brand-logo span {
  color: var(--new-blue);
  font-size: 29px;
  font-weight: 700;
  line-height: 32px;
}

.login_sec .btn-facebook {
  font-size: 15px;
  padding: 15px 20px !important;
}

.login_sec .sign_btn {
  font-size: 16px !important;
  padding: 15px 20px !important;
}

.register_sec {
  padding-top: 40px;
  padding-bottom: 40px;
}

.register_sec .sign_up_btn {
  padding: 15px 20px !important;
}

.mdi-facebook::before {
  font-size: 20px !important;
}

.brand-logo span {
  color: var(--new-blue);
  font-size: 29px;
  font-weight: 700;
  line-height: 32px;
}

.register_sec h4,
.register_sec h6 {
  color: var(--lite-grey);
  font-weight: 600 !important;
}

.auth form .form-group .form-control {
  outline: none;
}

.register_sec .already_have_acc {
  color: var(--lite-grey) !important;
  font-weight: 600;
}

.register_sec .login_link {
  color: var(--lite-grey) !important;
  text-decoration: underline;
}

.register_sec .login_link:hover {
  color: var(--blue) !important;
}

.register_sec .agree_label {
  font-weight: 600;
  color: var(--lite-grey) !important;
}

.login_sec .keep_me_sign {
  font-weight: 600;
  color: var(--lite-grey) !important;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper::before {
  width: 20px;
  height: 20px;
  border: 2px solid var(--lite-border-3) !important;
  border-radius: 0.25rem !important;
  top: 0px !important;
}

.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper::after {
  color: var(--lite-grey);
}

.form-check
  .form-check-label
  input[type="checkbox"]:checked
  + .input-helper::before {
  background-color: transparent !important;
}

.form-check .form-check-label input[type="checkbox"] + .input-helper::after {
  position: absolute;
  top: 0px !important;
  left: 1px !important;
}

.auth form .auth-form-btn {
  padding: unset !important;
  line-height: unset !important;
}

.register_sec .sign_up_btn {
  font-size: 16px;
}

.sidebar {
  min-height: 100vh !important;
  // height: 100% !important;
  background-color: var(--new-bg);
}

.sidebar .sidebar-brand-wrapper {
  background-color: var(--new-bg);
  justify-content: start !important;
}

.sidebar .nav .nav-item.profile .profile-desc .profile-name span {
  color: var(--lite-grey);
}

.profile-name h5 {
  color: var(--black) !important;
}

.navbar {
  background-color: var(--new-bg);
}

.footer {
  background-color: var(--new-bg);
}

.text-muted,
.preview-list .preview-item .preview-item-content p .content-category {
  color: var(--lite-grey) !important;
  font-weight: 600 !important;
}

.footer a {
  color: var(--blue);
}

.footer .float-sm-right {
  color: var(--lite-grey) !important;
}

.navbar .navbar-menu-wrapper {
  box-shadow: none;
}

.navbar-profile-name {
  color: var(--black) !important;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
  color: var(--lite-grey);
}

.navbar .navbar-menu-wrapper .search input {
  background-color: transparent;
  border: 1px solid #cccfd7;
  color: var(--lite-grey);
}

.sidebar .sidebar-brand-wrapper .sidebar-brand img {
  width: unset;
  height: 30px;
  margin: unset !important;
}

.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
  margin-bottom: 7px !important;
}

.sidebar .logo_txt {
  color: var(--new-blue);
  font-size: 20px;
  font-weight: 700;
  //   text-decoration: none;
}

.sidebar-brand-wrapper a {
  text-decoration: none;
}

.sidebar .nav .nav-item.nav-category .nav-link {
  color: var(--black) !important;
}

//   .sidebar .nav .nav-item .nav-link .menu-title{
//     color: var(--black);
//   }

.sidebar .nav .nav-item .nav-link .menu-title {
  // color: var(--black);
}

.sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: var(--black);
}

.sidebar .nav.sub-menu:hover {
  color: var(--black) !important;
}

.sidebar .nav .nav-item .nav-link {
  color: var(--black);
}

.sidebar .nav .nav-item .nav-link:hover {
  transition: none !important;
}

.dropdown-menu {
  background-color: white;
  box-shadow: none !important;
  color: var(--black) !important;
  border: 1px solid var(--lite-border-2) !important;
  // border-radius: 8px !important;
}

.preview-list .preview-item .preview-item-content p {
  color: var(--black);
  font-weight: 500;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--lite-theme-bg-1) !important;
}

.dropdown-divider {
  border: none !important;
}

.preview-list .preview-item .preview-thumbnail img,
.preview-list .preview-item .preview-thumbnail .preview-icon {
  background-color: var(--new-bg);
}

.preview-item-content h6 {
  color: var(--black);
}

// .sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.account-dropdown) > .nav-link {
//     background: white;
//     color: var(--black);
//   }

.table thead th {
  border-top: 1px solid #2c2e33 !important;
}

.modal-content {
  background-color: white !important;
  color: var(--black) !important;
  border: none !important;
}

.modal-header {
  // border-bottom-color: var(--lite-grey);
  border-bottom: none !important;
  font-size: 19px;
  font-weight: 600;
}

.modal-footer {
  // border-top-color: var(--lite-grey);
  border-top: none !important;
}

.form-group label {
  // color: var(--black) !important;
  color: var(--lite-grey) !important;
  font-size: 16px !important;
  font-weight: 600;
}

.form-control {
  border: 1px solid var(--lite-border-3) !important;
  background-color: transparent;
  // color: var(--black);
  color: var(--lite-grey) !important;
  border-radius: 5px;
}

.form-control:focus {
  background-color: transparent;
}

.custom-file .custom-file-label {
  background-color: transparent;
  border: 1px solid var(--lite-border-3) !important;
  border-radius: 5px;
  height: 46px;
  padding-top: 15px !important;
}

.add_edit_currency_txt {
  color: var(--black);
  font-weight: 600;
}

.custom-file .custom-file-label::after {
  background-color: var(--new-blue);
  padding-top: 10px !important;
}

.custom-file .custom-file-label:hover::after {
  background-color: var(--new-blue) !important;
}

.add_edit_cancel_btn {
  text-transform: none !important;
  font-size: 14px !important;
}

.sidebar .nav .nav-item.active > .nav-link {
  background: var(--new-blue) !important;
  color: #ffffff;
}

.sidebar
  .nav:not(.sub-menu)
  > .nav-item:hover:not(.nav-category):not(.account-dropdown)
  > .nav-link {
  background: var(--new-blue) !important;
  color: #ffffff;
}

.sidebar .nav .nav-item .nav-link {
  font-weight: 600;
}

.sidebar .nav .nav-item:hover .menu-icon {
  background-color: white !important;
}

.sidebar .nav .nav-item.active .menu-icon {
  background-color: white !important;
}

.sidebar .nav {
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    background-color: var(--new-blue) !important;
    color: white !important;
  }

  .sidebar-icon-only .sidebar .sidebar-brand-wrapper .brand-logo {
    display: none !important;
  }
}

.settings .reset_pwd {
  text-decoration: underline;
}

.form-control::placeholder {
  color: var(--lite-grey);
}

.register_sec .form-group p {
  color: var(--lite-grey) !important;
  font-size: 16px !important;
  font-weight: 600;
}

.form-control {
  height: 48px !important;
}

.dashboard_sec h3 {
  color: var(--lite-grey) !important;
}

.forgot_pwd_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer a {
  color: var(--blue) !important;
}

.footer span {
  // color: var(--lite-grey) !important;
  color: var(--black);
  font-size: 16px !important;
}

.navbar .navbar-brand-wrapper {
  background-color: var(--new-bg) !important;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
  width: 35px !important;
  height: unset !important;
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
  line-height: unset !important;
  display: flex !important;
}

.hidden_sidebar_img {
  width: 30px !important;
  height: 30px !important;
}

.table thead th {
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ccc !important;
}

@media (max-width: 480px) {
  .login_sec .brand-logo span {
    font-size: 22px !important;
  }

  .auth .brand-logo img {
    width: 50px !important;
  }

  .forgot_pwd_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .brand-logo span {
    font-size: 22px !important;
  }

  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .dropdown-menu.navbar-dropdown {
    transform: translate(-20px, 71px) !important;
    width: 90% !important;
  }
}

.card .card-title {
  color: var(--black);
  font-weight: 600;
}

.noti-content {
  background-color: var(--new-bg);
}

.noti-content .login_success_txt {
  color: var(--black);
}

.noti-content .date_time {
  color: var(--lite-grey);
}

.noti-content .side_bg {
  width: 6px;
  background-color: var(--blue);
}

.noti-content .mark_as_read {
}

.noti-content .mark_us_read_icon {
}

.noti-content_marked {
  background-color: #9d9d9d;
}

.noti-content_marked .login_success_txt {
  color: #ccc;
}

.noti-content_marked .date_time {
  color: #ccc;
}

.noti-content_marked .side_bg {
  width: 6px;
  background-color: #605a5a;
}

.noti-content_marked .mark_as_read {
  color: #ccc;
}

.noti-content_marked .mark_us_read_icon {
  color: #ccc;
}

@media (max-width: 380px) {
  .notifications .login_success_txt {
    font-size: 13px;
  }

  .notifications .date_time {
    font-size: 13px;
  }

  .notifications .mark_as_read {
    font-size: 13px;
  }
}

.back_to_btn {
  color: var(--lite-grey);
  text-decoration: underline;
}

.back_to_btn:hover {
  color: var(--blue);
}

.modal-header .close {
  margin: -14px 0px -25px auto !important;
  padding: 0px !important;
}

.modal-header .close span {
  font-size: 40px;
}

.modal-header .close:hover {
  scale: 1.1 !important;
}

.modal-body {
  padding: 0.5rem 1rem 1.5rem 1rem !important;
}

.notifications_drop_header_txt {
  padding: 11px 13px;
}

.user_profile_drop {
  padding: 0px !important;
}

.user_profile_drop .preview-item {
  padding: 11px 11px !important;
  width: 100% !important;
}

@media (min-width: 576px) and (max-width: 991px) {
  .content-wrapper {
    background-color: white !important;
    min-height: calc(100vh - 150px) !important;
  }
}

.edit_settings:hover {
  text-decoration: none;
}

@media (max-width: 480px) {
  .my_profile_page .user_detail_name {
    font-size: 15px !important;
  }

  .my_profile_page .user_detail_value {
    font-size: 15px !important;
  }
}

.my_profile_page .user_detail_name {
  color: var(--lite-grey);
  font-weight: 500;
  font-size: 18px;
}

.my_profile_page .user_detail_value {
  color: var(--lite-grey);
  font-weight: 600;
  font-size: 18px;
}

.custom_support_dropdown {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--lite-border-3) !important;
  background-color: transparent !important;
  color: var(--lite-grey) !important;
  border-radius: 5px;
  height: 48px !important;
  box-shadow: none !important;
}

.custom_support_dropdown:active {
  border: 1px solid var(--lite-border-3) !important;
  background-color: transparent !important;
  color: var(--lite-grey) !important;
  box-shadow: none !important;
}

.custom_support_dropdown:focus {
  border: 1px solid var(--lite-border-3) !important;
  background-color: transparent !important;
}

.custom_support_dropdown:hover {
  background-color: transparent !important;
}

.btn-primary:not(.btn-light):not(.btn-secondary):focus,
.custom-file
  .custom-file-label:not(.btn-light):not(.btn-secondary):focus::after,
.btn-primary:not(.btn-light):not(.btn-secondary):active,
.custom-file
  .custom-file-label:not(.btn-light):not(.btn-secondary):active::after {
  background-color: transparent !important;
  background: transparent !important;
  border: 1px solid var(--lite-border-3) !important;
}

.support_ticket_drop .dropdown-item {
  color: var(--lite-grey) !important;
}

.support_ticket_drop .dropdown-menu {
  width: 100% !important;
}

.accordion_sec .acc-toggle .sec {
  color: var(--black);
  font-weight: 600;
}

.accordion_sec .acc-toggle .sec {
  display: flex;
  // flex-direction: column;
}

.accordion_sec .acc-toggle .content {
  // color: var(--lite-grey);
  color: #605e5a;
  font-weight: 600;
  padding-left: 10px;
}

.accordion_sec .acc-toggle .status {
  color: var(--blue);
  padding-left: 10px;
}

.accordion_sec .acc-toggle .add_icon svg {
  color: var(--black);
  font-weight: 600;
  font-size: 18px;
}

.accordion_sec .acc-toggle .minus_icon svg {
  color: var(--black);
  font-weight: 600;
  font-size: 18px;
}

.accordion_sec .accor_body_sec .create-date {
  // color: var(--lite-grey);
  color: #605e5a;
  font-weight: 500;
}

// attachment button

.accor_choose_file {
  display: inline-block;
  background-color: var(--blue);
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0px !important;
  font-size: 14px;
}

.accor_body_sec {
  // color: var(--lite-grey);
  color: #605e5a;
  font-weight: 500;
}

// .accor_body_sec .user_detail_sec{

// }

.accor_body_sec .attachment_logo path {
  color: var(--blue);
  stroke: var(--blue);
}

.attachment_logo {
  margin-top: -2px !important;
}

.custom_bootstrap_table .table th,
.custom_bootstrap_table .table td {
  text-align: center;
  width: 210px !important;
}

@media (max-width: 480px) {
  .accordion_sec .acc-toggle .content {
    padding-left: 0px !important;
  }

  .accordion_sec .acc-toggle .sec {
    display: flex;
    flex-direction: column;
    font-size: 13px !important;
  }

  .accordion_sec .acc-toggle .status {
    padding-left: 0px;
  }
}

.react-bootstrap-table {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 991px) {
  // .custom_bootstrap_table .table th, .custom_bootstrap_table .table td{
  //   width: 170px !important;
  // }
  .navbar .navbar-menu-wrapper {
    justify-content: flex-end;
  }
}

.accordion_sec .card {
  border-radius: 0px !important;
  margin-bottom: 10px;
  border: 1px solid #ccc !important;
  cursor: pointer;
}

.mark_as_read:hover {
  text-decoration: none !important;
}

.noti-content a {
  text-decoration: none !important;
}

.noti-content_marked a {
  text-decoration: none !important;
}

.notifications a {
  text-decoration: none;
}

.react-bootstrap-table-pagination .dropdown-toggle {
  height: 35.5px !important;
  box-shadow: none !important;
}

.react-bootstrap-table-pagination .dropdown-item {
  color: var(--black);
}

.react-bootstrap-table-pagination .dropdown-item:hover {
  color: var(--black) !important;
}

.navbar .navbar-menu-wrapper .navbar-toggler {
  color: var(--lite-grey);
}

.react-bootstrap-table-pagination {
  // margin-top: 10px !important;
  flex-wrap: nowrap;
  margin-top: 30px;
}

.currency_page_custom_align {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.currency_page_custom_align .row {
  width: 100%;
  justify-content: center;
}

.add_edit_custom_dropdown {
  width: 100% !important;
}

.add_edit_custom_dropdown button {
  background-color: transparent !important;
  border: 1px solid var(--lite-border-3) !important;
  border-radius: 5px;
  height: 46px;
  // padding-top: 15px !important;
  width: 100%;
  box-shadow: none;
  color: var(--lite-grey) !important;
  text-align: end !important;
}

.add_edit_custom_dropdown button:focus {
  box-shadow: none !important;
}

.add_edit_custom_dropdown .dropdown-item {
  color: var(--lite-grey) !important;
  text-align: center;
}

.add_edit_custom_dropdown label {
  color: var(--lite-grey) !important;
}

.currency_custom_radio .form-check {
  display: flex;
  align-items: center;
}

.currency_custom_radio input {
  width: 20px;
  height: 20px;
}

.currency_custom_radio label {
  position: relative;
  top: 3px;
  color: var(--lite-grey) !important;
  font-size: 16px !important;
  font-weight: 600;
}

.add_edit_custom_dropdown {
  position: relative;
}

.add_edit_custom_dropdown .dropdown-menu {
  // width: 100%;
  // left: 400px !important;

  right: 0px !important;
  left: auto !important;
}

.img_upload_add {
  max-width: 150px !important;
  border-radius: 5px;
}

.eye_btn_form_grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--lite-border-3) !important;
  color: var(--lite-grey) !important;
  border-radius: 0.375rem;
}

.auth form .form-group .eye_btn_form_grp input.form-control {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 0px !important;
}

.auth form .form-group .eye_btn_form_grp .fa {
  padding-right: 10px !important;
  padding-left: 10px !important;
  cursor: pointer;
}
.auth form .form-group .eye_btn_form_grp .fa:hover {
  color: var(--new-blue) !important;
}

.commonBtn.Redbtn {
  background-color: #a30b0b !important;
  background: #a30b0b !important;
  border: 1px solid #a30b0b !important;
}
.commonBtn.Redbtn:hover .fa-trash {
  color: #fff !important;
}

.custom_bootstrap_table.user_comm_table th:first-child,
.custom_bootstrap_table.user_comm_table td:first-child {
  max-width: 80px !important;
  width: 80px !important;
}

.custom_bootstrap_table.user_comm_table th:nth-child(3),
.custom_bootstrap_table.user_comm_table td:nth-child(3) {
  min-width: 250px !important;
  width: 250px !important;
}
.copy_icon_hover:hover {
  cursor: pointer;
}
.copy_icon_hover:hover {
  color: #0090e7 !important;
}

// start of hariharan css
.usr_back_icn {
  color: var(--new-blue) !important;
  font-size: 40px;
  cursor: pointer;
  transition: 0.3s;
}
.usr_back_icn:hover {
  transform: scale(95%);
}
.new_btn {
  border: 1px solid var(--new-blue) !important;
  outline: none;
  background: var(--new-blue) !important;
  color: white !important;
  padding: 5px 20px !important;
  border-radius: 10px !important;
  font-weight: 700 !important;
  transition: 0.3s !important;
}
.new_btn:hover {
  border: 1px solid var(--new-blue) !important;
  background: none !important;
  color: black !important;
}
// end of hariharan css

.navdropnotfymenu{
  min-width:220px;
}