.navbar {
  font-weight: normal;
  background: $navbar-bg;
  left: $sidebar-width-lg;
  transition:left $action-transition-duration $action-transition-timing-function;
  -webkit-transition:left $action-transition-duration $action-transition-timing-function;
  -moz-transition:left $action-transition-duration $action-transition-timing-function;
  -ms-transition:left $action-transition-duration $action-transition-timing-function;
  @media (max-width:992px) {
    left: 0;
  }
  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    background: $navbar-bg;
    width: $sidebar-width-lg;
    height: $navbar-height;
    @media (max-width: 991px) {
      width: 55px;
    }
    .navbar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: 0rem 0rem 0rem 1.3rem;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        width: calc(#{$sidebar-width-lg} - 120px );
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle;
      }
      &.brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 50px );
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
  }
  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    box-shadow: $navbar-box-shadow;
    -webkit-box-shadow: $navbar-box-shadow;
    -moz-box-shadow: $navbar-box-shadow;  
    color: $navbar-menu-color;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    height: $navbar-height;
    @media (max-width: 991px) {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
    }
    .navbar-toggler {
      border: 0;
      color: $text-muted;
      height: $navbar-height;
      @include border-radius(0px);
      &:not(.navbar-toggler-right) {
        font-size: 0.875rem;
        @media (max-width: 991px) {
          display: none;
        }
      }
      &.navbar-toggler-right{
        @media (max-width:991px){
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }
    .search-field {
      .input-group {
        input {
          font-size: $default-font-size;
          padding: .5rem;
          @include input-placeholder {
            font-size: $navbar-font-size;
            color: $navbar-menu-color;
            font-weight: $font-weight-light;
          }
        }
        i {
          font-size: 17px;
          margin-right: 0;
          color: $navbar-menu-color;
        }
        .input-group-text {
          background: transparent;
        }
      }
      .rtl & {
        @extend .ml-0;
        @extend .mr-4;
      }
    }
    .count-indicator {
      position: relative;

      .count-symbol,
      .count-number {
        position: absolute;
        border-radius: 100%;
        border: 2px solid $navbar-bg;
      }
      .count-symbol {
        top: 17px;
        right: -3px;
        width: 10px;
        height: 10px;
      }
      .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: $white;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center;
      }
      &:after {
        display: none;
      }
      .count {
        position: absolute;
        left: 66%;
        width: 7px;
        height: 7px;
        color: $white;
        border-radius: 100%;
        text-align: center;
        font-size: .625rem;
        line-height: 1.5;
        top: 7px;
      }
    }
    .navbar-nav {
      flex-direction: row;
      align-items: center;
      .nav-item {
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          margin-left: 1rem;
          margin-right: 1rem;
          white-space: nowrap;
          line-height: 1;
          @media (max-width: 767px) {
            margin-left: .8rem;
            margin-right: .8rem;
          }
          i {
            font-size: $navbar-icon-font-size;
          }
          .navbar-profile {
            display: flex;
            font-weight: normal;
            align-items: center;
            .navbar-profile-name {
              white-space: nowrap;
              margin-left: 1rem;
            }
            i {
              color: #a7afb7;
            }
          }
          &.create-new-button {
            padding: 0.375rem 0.75rem;
          }
        }
        &.dropdown {
          .dropdown-toggle {
            &:after {
              color: theme-color(primary);
              font-size: 1rem;
            }
          }
          .dropdown-menu {
            @extend .dropdownAnimation;
            margin-top: 1.15rem;
            border: none;
            @include border-radius(4px);
            &.navbar-profile-dropdown-menu {
              margin-top: 0.65rem;
            }
            &.create-new-dropdown-menu {
              margin-top: 1.35rem;
            }
            &.navbar-dropdown {
              font-size: 0.9rem;
              -webkit-box-shadow: 0px 0px 35px -3px rgb(0, 0, 0);
              -moz-box-shadow: 0px 0px 35px -3px rgb(0, 0, 0);
              box-shadow: 0px 0px 35px -3px rgb(0, 0, 0);
              .rtl & {
                right: auto;
                left: 0;
              }
              padding: 0;

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 11px 13px;
                cursor: pointer;
                &:hover {
                  color: #fff;
                }
                i {
                  font-size: 17px;
                }
                .ellipsis {
                  max-width: calc(100% - 40px);
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rtl & {
                  i{
                    @extend .mr-0;
                    margin-left: 10px;
                  }
                }
              }
              .dropdown-divider {
                margin: 0;
              }
            }
          }
          @media (max-width: 991px) {
            position: static;
            .navbar-dropdown {
              margin-left: 20px;
              right: 30px;
            }
          }
        }
        &.nav-profile {
          .nav-link {
            @extend .d-flex;
            .nav-profile-img {
              position: relative;
              width: 32px;
              height: 32px;
              img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
              }
              .availability-status {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: 2px solid #ffffff;
                bottom: 5px;
                right: -5px;
                &.online {
                  background: theme-color(success);
                }
                &.offline {
                  background: theme-color(danger);
                }
                &.busy {
                  background: theme-color(warning);
                }
              }
            }
            .nav-profile-text {
              margin-left: 1.25rem;
              .rtl & {
                margin-left: 0;
                margin-right: 1.25rem;
              }
              p {
                line-height: 1;
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
            &.dropdown-toggle {
              &:after {
                line-height: 2;
              }
            }
          }
        }
      }
      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
        .nav-item {
          line-height: 1;
        }
      }
    }
    .search {
      input {
        background: $card-bg;
        padding: 13px 20px 11px 20px;
        border: 1px solid #2c2e33;
        &::placeholder {
          color: $text-muted;
          font-size: $input-placeholder-font-size;
        }
      }
    }
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;
    .navbar-brand-wrapper {
      width: 75px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }
  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}
@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
}

